<template>
  <main class="gx-layout-content ant-layout-content">
    <div class="gx-main-content-wrapper">
      <div class="gx-page-error-container">
        <div class="gx-page-error-content">
          <div class="gx-error-code gx-mb-4">
            404
          </div>
          <h2 class="gx-text-center"><span>Oops, an error has occurred. Page not found!</span></h2>
          <p class="gx-text-center"><a class="gx-btn gx-btn-primary" href="/dashboard"><span>Go to Home</span></a></p>
        </div>
      </div>
    </div>
    </footer>
  </main>
</template>

<script>
  export default {
    name: "404"
  }
</script>

<style scoped>

</style>
