var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("main", { staticClass: "gx-layout-content ant-layout-content" }, [
      _c("div", { staticClass: "gx-main-content-wrapper" }, [
        _c("div", { staticClass: "gx-page-error-container" }, [
          _c("div", { staticClass: "gx-page-error-content" }, [
            _c("div", { staticClass: "gx-error-code gx-mb-4" }, [
              _vm._v("\n          404\n        ")
            ]),
            _c("h2", { staticClass: "gx-text-center" }, [
              _c("span", [
                _vm._v("Oops, an error has occurred. Page not found!")
              ])
            ]),
            _c("p", { staticClass: "gx-text-center" }, [
              _c(
                "a",
                {
                  staticClass: "gx-btn gx-btn-primary",
                  attrs: { href: "/dashboard" }
                },
                [_c("span", [_vm._v("Go to Home")])]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }